<template>
  <div class="game-wrapper">
    <ul class="game-list desktop">
      <li v-for="(game, index) of centerGames" :key="index" :class="{ 'game-list--top': game.class === 'final' }">
        <GameItem :game="game" class-name="favorite" />
        <div v-if="jackpotNumber(game.link)" class="jackpot">
          <AnimateNumber :number="jackpotNumber(game.link)" show-coin />
        </div>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { LOBBY_GAME_URLS } from '~/config/page-url'
import { getGameCenter } from '~/constants/home'
const GameItem = defineAsyncComponent(() => import('~/components/desktop/pages/home/game-center/game-center-item.vue'))
const AnimateNumber = defineAsyncComponent(() => import('~/components/common/animate-number.vue'))

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

const { HOME_SPORTS } = getGameCenter()
const centerGames = reactive(HOME_SPORTS)

const jackpotNumber = (link: string): number => {
  switch (link) {
    case LOBBY_GAME_URLS.NO_HU:
      return sumJackpot.value.jackpotNohu
    case LOBBY_GAME_URLS.BAN_CA:
      return sumJackpot.value.jackpotFishing
    case LOBBY_GAME_URLS.TABLE_GAME:
      return sumJackpot.value.jackpotIngame
    default:
      return 0
  }
}
</script>
<style lang="scss" scoped src="~/assets/scss/components/desktop/pages/home/game-center/list.scss"></style>
